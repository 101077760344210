import React, { Suspense } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";


// Home Pages
const Home = React.lazy(() => import("./components/pages/Home"));

// Pages
const About = React.lazy(() => import("./components/pages/About"));
const Services = React.lazy(() => import("./components/pages/Services"));
const Faq = React.lazy(() => import("./components/pages/Faq"));
const Contact = React.lazy(() => import("./components/pages/Contact"));
const Error = React.lazy(() => import("./components/pages/Error"));
const Agentdetails = React.lazy(() => import("./components/pages/Agentdetails"));
 
// Listings
const Listinggrid = React.lazy(() => import("./components/pages/Listinggrid"));
const Listingdetailstwo = React.lazy(() => import("./components/pages/Listingdetailstwo"));
const Agencydetails = React.lazy(() => import("./components/pages/Agencydetails"));

// Sentry
Sentry.init({
  dsn: "https://2038aee804a84208be33c0d0bfd66e5c@o1214125.ingest.sentry.io/6354170",
  integrations: [new BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

function App() {
  return (
    <Router>
      <Suspense fallback={<div></div>}>
      
        <Switch>
          {/* Homepages */}
          <Route exact path="/" component={Home} />
          {/* Pages */}
          <Route path="/about" component={About} />
          <Route path="/services" component={Services} />
          <Route path="/error-404" component={Error} />
          {/* Listings */}
          <Route path="/listing-properties" component={Listinggrid} />
          <Route path="/listing-property-details" component={Listingdetailstwo} />
          <Route path="/office/:id" component={Agencydetails} />

          <Route path="/:id" component={Agentdetails} />
        </Switch>
      </Suspense>
    </Router>
  );
}

export default App;
